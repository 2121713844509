.footer-container {
  background-color: #f0f0f0;
  color: black;
    padding: 20px;
  }
  
  /* Footer content */
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Copyright text */
  .footer-copyright {
    margin: 0;
    font-size: 14px;
  }
  
  /* Contact details */
  .footer-contact {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .footer-contact-label {
    margin-right: 5px;
    font-weight: bold;
  }
  
  .footer-email,
  .footer-contact-info {
    color: #007bff;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .footer-contact {
      margin-top: 5px;
    }
  
    .footer-contact-label {
      display: none;
    }
  }