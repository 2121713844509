.home-container {
  position: relative;
  min-height: 83vh;
  overflow: hidden; /* Para evitar que el fondo con blur se desborde */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.image-container {
  position: relative; /* Asegura que los elementos internos mantengan el orden de apilamiento */
  width: 500px; /* Ajusta el tamaño de las imágenes */
  height: 400px;
  margin: 50px;
  margin-top: 200px;
  z-index: 1; /* Coloca las imágenes por encima del fondo con blur */
}

.image-container img {
  border-radius: 10px;
  width: 100%; /* Ajusta el tamaño de la imagen al 100% del contenedor */
  height: 100%;
  object-fit: cover; /* Ajusta el modo de ajuste de la imagen para que cubra todo el contenedor */
  overflow: hidden;
}

.image-container a {
  border-radius: 10px;
  text-decoration: none;
  color: #fff; /* Ajusta el color del texto según el fondo de la imagen */
  font-size: 16px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Ajusta el color de fondo del texto */
  opacity: 1; /* Mostrar el hipervínculo siempre visible en la imagen */
}

/* Estilos para el fondo de imagen en blur */
.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../imagenes/fondosImagenes.jpeg') center/cover no-repeat fixed;
  filter: blur(8px);
  z-index: 0; /* Coloca el fondo con blur por debajo de las imágenes */
}